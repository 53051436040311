(function ($) {
  'use strict';

  $(document).on('click', '[ui-nav] a', function (e) {
    var $this = $(e.target); var $active; var $li;
    $this.is('a') || ($this = $this.closest('a'));

    $li = $this.parent();
    $active = $li.siblings('.active');
    $li.toggleClass('active');
    $active.removeClass('active');
  });
})(jQuery);
