import 'core-js/stable';
import 'regenerator-runtime/runtime';

// global.$ = global.jQuery = require('jquery');

/* Project specific Javascript goes here. */

/*
Formatting hack to get around crispy-forms unfortunate hardcoding
in helpers.FormHelper:

    if template_pack == 'bootstrap4':
        grid_colum_matcher = re.compile('\w*col-(xs|sm|md|lg|xl)-\d+\w*')
        using_grid_layout = (grid_colum_matcher.match(self.label_class) or
                             grid_colum_matcher.match(self.field_class))
        if using_grid_layout:
            items['using_grid_layout'] = True

Issues with the above approach:

1. Fragile: Assumes Bootstrap 4's API doesn't change (it does)
2. Unforgiving: Doesn't allow for any variation in template design
3. Really Unforgiving: No way to override this behavior
4. Undocumented: No mention in the documentation, or it's too hard for me to find
*/
$('.form-group').removeClass('row');
require('../scripts/ui-device.js');
require('../scripts/ui-nav.js');
require('../scripts/ui-form.js');

$('.disable-on-click').click(function() {
  $(this).prop('disabled', true);
});

window.mixpanel = require('mixpanel-browser');

if (window.ENV === 'production') {
  window.mixpanel.init('c805ed9c4e73d1093d18978a1d79ed1b');
} else {
  window.mixpanel.init('0445682d0f8513783774636132cbcf79');
}

if (userId) {
  window.mixpanel.identify(userId);
  window.mixpanel.people.set({
    name: userName,
    email: userEmail,
    org: org
  });
}

window.mixpanel.track('page viewed', {
  'page name': document.title,
  'url': window.location.pathname,
  'org': org
});

$(document).ready(function() {
  $('#registerNewDeposit').on('click', () => {
    window.mixpanel.track('Click register new deposit');
  });
  $('.deposit-list .table tr').on('click', () => {
    window.mixpanel.track('Click view deposit');
  });
  $('.deposit-detail button#activate_deposit').on('click', () => {
    window.mixpanel.track('Click pay deposit');
  });
  $('.deposit-detail button#confirm_deposit_transfer').on('click', () => {
    window.mixpanel.track('Click confirm deposit payment');
  });
  $('.deposit-detail').on('click', '[data-mp="viewBatchPayment"], a#viewBatchPayment', () => {
    window.mixpanel.track('Click view batch payment');
  });
  $('.deposit-detail a#signDepositCertificate').on('click', () => {
    window.mixpanel.track('Click sign deposit');
  });
});
